import { render, staticRenderFns } from "./inputFromPin.vue?vue&type=template&id=5216e2e0&scoped=true"
import script from "./inputFromPin.vue?vue&type=script&lang=js"
export * from "./inputFromPin.vue?vue&type=script&lang=js"
import style0 from "./inputFromPin.vue?vue&type=style&index=0&id=5216e2e0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5216e2e0",
  null
  
)

export default component.exports